import { createReducer } from "@reduxjs/toolkit";
import {
  OBJECTIVE_ERROR,
  OBJECTIVE_LIST,
  OBJECTIVE_LOADING,
  CREATE_OBJECTIVE,
  DELETE_OBJECTIVE,
  EDIT_OBJECTIVE,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const objectiveReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(OBJECTIVE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(OBJECTIVE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(OBJECTIVE_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_OBJECTIVE, (state, { payload }) => {
      console.log(payload);

      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_OBJECTIVE, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_OBJECTIVE, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default objectiveReducer;
