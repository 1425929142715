import { createReducer } from "@reduxjs/toolkit";
import {
  APP_SYSTEM_ERROR,
  APP_SYSTEM_LIST,
  APP_SYSTEM_LOADING,
  CREATE_APP_SYSTEM,
  DELETE_APP_SYSTEM,
  EDIT_APP_SYSTEM,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const appSystemReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(APP_SYSTEM_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(APP_SYSTEM_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(APP_SYSTEM_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_APP_SYSTEM, (state, { payload }) => {
      console.log(payload);

      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_APP_SYSTEM, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_APP_SYSTEM, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default appSystemReducer;
